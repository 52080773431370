import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";
import ReactPlayer from "react-player";

const SafelySecuredProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              SafelySecured.<br />
              Inspired locally.<br />
              Manufactured in China.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              The story of SafelySecured is a brilliant display of Australian ingenuity and entrepreneurship. Matt McShane, founder and brainchild of SafelySecured was worried about leaving his dogs outside of shops, cafes, and supermarkets for fear of them being stolen.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Matt came up with a brilliant concept and partnered with our ongoing development team, to create the world's first UN-STEALABLE dog leash!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              That's right, this harness, collar & leash combo isn't just gorgeous, it's thief proof.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
            Here's how we made amazing happen for SafelySecured.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/safely-secured-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8593;$25K</span>
            </p>
            <p className="font-black text-xl">ORDER REVENUE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>600</span>
            </p>
            <p className="font-black text-xl">UNITS ORDERED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;{'<'}2%</span>
            </p>
            <p className="font-black text-xl">DEFECT RATE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;30%</span>
            </p>
            <p className="font-black text-xl">COSTS OF GOODS</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/safely-secured-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              A promise is a promise
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              SafelySecured mission was to create a durable product and brand that lives up to its name. The products needed to be easy-to-use, beautiful, durable, comfortable for the pups and above all - safe from prying hands!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci was engaged by SafelySecured to go out there and scour the Asian continent for suitable manufacturing partners with not only the skills, but the appetite for novel product development.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci was able to develop the entire product range, allowing Matt to spend all of his time and energy on the place that needs it most - spruiking this incredible invention to the world, selling, marketing, networking, and building the brand up to the moon like a rocketship.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Profit lines. Bottom lines. Production lines. Sourci.
            <span className="block text-sourci-pink">Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              1126 KG of force, in 16 variants
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              When Sourci was able to find a factory that could achieve beyond the expectations of SafelySecured, Matt decided to raise the bar to scale their original order from 10 to 16 different sizes and varieties, opening his poodle-saving products to dogs of every size and shape.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              During testing, Matt was able to land on a 1126KG load rating for these puppies (the harnesses, not the actual puppies don't worry).
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With Sourci being able to maintain low defect rates, super easy fast-tracked communication, and taking care of the full supply chain, SafelySecured were able to make a howling sprint into the market, and even landed on Channel 7 News.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/safely-secured-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="py-24 bg-[#ff692c]">
        <div className="xl:container mx-auto px-6">
          <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-40 gap-x-60 w-full justify-center items-center">
            <div>
              <div className="mx-auto flex overflow-hidden rounded-3xl relative pt-[56.25%]">
                <ReactPlayer url="https://www.youtube.com/embed/D5yoSMLyAG8?autoplay=0&mute=0&controls=0&origin=https%3A%2F%2Fsourci.com.au&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"
                  controls
                  className="absolute top-0 left-0"
                  width="100%"
                  height="100%"
                  config={{
                    youtube: {
                        playerVars: {
                            modestbranding: 1,
                        },
                    },
                  }} />
              </div>
            </div>
            <div>
              <p className="text-white text-4xl lg:text-5xl font-bold">
              Safely Secured featured on Channel 9 News!<br />
              Check it out here
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - SafelySecured" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="safely-secured"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default SafelySecuredProject;

export function Head() {
  return (
    <Seo title="SafelySecured | Our Projects | Sourci"
      description="Discover how Sourci worked with SafelySecured to manufacture a novel Australian invention"
      canonical="/projects/safely-secured" />
  )
}